import React, {useEffect} from "react";
import {Category} from "../../../../api/rm";
import {useDispatch} from "react-redux";
import {
    nullifyCategoryStore,
    saveCategory,
    setCategory
} from "../../../../store/category/actions/CategoryActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import FormActionContainer from "../../../Form/FormActionContainer";
import {DebounceInput} from "react-debounce-input";

const EditCategoryForm = (props: Category) => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        return function () {
            dispatch(nullifyCategoryStore());
        };
    }, []);

    const onCategoryNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setCategory({...props, name: event.target.value}));
    };

    const saveCategoryToService = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveCategory(props));

        if (!success) return;

        backToCategoryList();
    };

    const backToCategoryList = () => {
        history.replace(routeNames.categoryList.path);
    };
    return (
        <React.Fragment>
            <div className="fadeIn pt-3">
                <FormHeader headerName={"Category Form"} />
                <FormRow rowName={"Category Name"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className="input-fields"
                        value={props.name}
                        onChange={onCategoryNameChanged}
                        placeholder="Enter category name..."
                        type={"text"}
                    />
                </FormRow>
                <div className="row-modifier">
                    <FormActionContainer>
                        <MCButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={saveCategoryToService}
                            colour={ButtonColourOptions.Yellow}
                        />
                        <MCButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={backToCategoryList}
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </FormActionContainer>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditCategoryForm;
