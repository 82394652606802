import {
    Answer,
    newQuestionWithAnswerAndCommentsAndDate,
    newSectionDetailItem,
    QuestionWithAnswerAndCommentsAndDate,
    SectionDetails
} from "../auditHelpers";

export interface FiveYearFireSafetyAudit {
    fireExtinguisherCalibrationDetails: QuestionWithAnswerAndCommentsAndDate;
    fiveYearElectricalInspectionDetails: QuestionWithAnswerAndCommentsAndDate;
    fiveYearCheckDetails: SectionDetails;
}

export const fireSafety5YearlyQuestionOrder: string[] = [
    "fireExtinguisherCalibrationDetails",
    "fiveYearElectricalInspectionDetails",
    "fiveYearCheckDetails"
];

export function generateBlankFiveYearFireSafetyAudit(): FiveYearFireSafetyAudit {
    return {
        fireExtinguisherCalibrationDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire extinguisher 5 year calibration?",
            0,
            Answer.No,
            "Calibration date:"
        ),
        fiveYearElectricalInspectionDetails: newQuestionWithAnswerAndCommentsAndDate(
            "5 year electrical inspection?",
            0,
            Answer.No,
            "Inspection date:"
        ),
        fiveYearCheckDetails: newSectionDetailItem("5 year check comments/details")
    };
}
