import React from "react";
import {
    depotBathroomQuestionOrder,
    depotClinicalWasteGeneralWasteQuestionOrder,
    depotElectricalQuestionOrder,
    depotExternalAreaQuestionOrder,
    depotGeneralSignageQuestionOrder,
    depotKitchenQuestionOrder,
    depotOfficeQuestionOrder,
    depotSecurityQuestionOrder,
    depotStorageQuestionOrder,
    PremisesDepotAudit
} from "../../../../../../../store/audit/helpers/Premises/premisesDepotAudit";
import FormHeader from "../../../../../../Form/FormHeader";
import QuestionBlock from "../../General/QuestionBlock";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";

const PremisesDepotAuditForm = (props: PremisesDepotAudit) => {
    return (
        <React.Fragment>
            <FormHeader headerName={getUiFriendlyText(AuditType.AuditPremisesDepot)} />
            <QuestionBlock
                headerName={"Office"}
                checklist={props.officeQuestions}
                nestingList={["premisesDepotAudit", "officeQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotOfficeQuestionOrder}
            />
            <QuestionBlock
                headerName={"Kitchen"}
                checklist={props.kitchenQuestions}
                nestingList={["premisesDepotAudit", "kitchenQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotKitchenQuestionOrder}
            />
            <QuestionBlock
                headerName={"Electrical"}
                checklist={props.electricalQuestions}
                nestingList={["premisesDepotAudit", "electricalQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotElectricalQuestionOrder}
            />
            <QuestionBlock
                headerName={"Security"}
                checklist={props.securityQuestions}
                nestingList={["premisesDepotAudit", "securityQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotSecurityQuestionOrder}
            />
            <QuestionBlock
                headerName={"Bathroom"}
                checklist={props.bathroomQuestions}
                nestingList={["premisesDepotAudit", "bathroomQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotBathroomQuestionOrder}
            />
            <QuestionBlock
                headerName={"Clinical Waste and General Waste"}
                checklist={props.clinicalWasteGeneralWasteQuestions}
                nestingList={["premisesDepotAudit", "clinicalWasteGeneralWasteQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotClinicalWasteGeneralWasteQuestionOrder}
            />
            <QuestionBlock
                headerName={"Storage"}
                checklist={props.storageQuestions}
                nestingList={["premisesDepotAudit", "storageQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotStorageQuestionOrder}
            />
            <QuestionBlock
                headerName="General Signage"
                checklist={props.generalSignageQuestions}
                nestingList={["premisesDepotAudit", "storageQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotGeneralSignageQuestionOrder}
            />
            <QuestionBlock
                headerName={"External Areas"}
                checklist={props.externalAreaQuestions}
                nestingList={["premisesDepotAudit", "externalAreaQuestions"]}
                showYesNoAnswerOnly={false}
                questionOrder={depotExternalAreaQuestionOrder}
            />
        </React.Fragment>
    );
};

export default PremisesDepotAuditForm;
