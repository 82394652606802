import {AuditQuestion} from "../../formHelpers";
import {useDispatch} from "react-redux";
import {
    CallPointAnswer,
    QuestionWithAnswer,
    QuestionWithAnswerAndComments,
    QuestionWithAnswerAndCommentsAndDate,
    SectionDetails
} from "../../../../../../store/audit/helpers/auditHelpers";
import {setAuditForm} from "../../../../../../store/audit/actions/AuditActions";
import React from "react";
import FormHeader from "../../../../../Form/FormHeader";
import {generateQuestionComponents} from "../../Helpers/auditFormHelpers";

const QuestionBlock = ({
    checklist,
    nestingList,
    headerName,
    showYesNoAnswerOnly,
    questionOrder
}: QuestionBlockProps<any>) => {
    const dispatch = useDispatch();
    const updateCallback = (
        key: string,
        updatedQuestion:
            | QuestionWithAnswer
            | QuestionWithAnswerAndComments
            | QuestionWithAnswerAndCommentsAndDate
            | SectionDetails
            | CallPointAnswer
    ) => {
        dispatch(setAuditForm({nestingList, key, updatedQuestion}));
    };

    return (
        <React.Fragment>
            <FormHeader headerName={headerName} />
            {generateQuestionComponents({
                checklist,
                updateCallback,
                showYesNoAnswerOnly,
                questionOrder: questionOrder ? questionOrder : []
            })}
        </React.Fragment>
    );
};

export default QuestionBlock;

interface QuestionBlockProps<T> extends AuditQuestion<T> {
    headerName: string;
    showYesNoAnswerOnly: boolean;
    questionOrder?: string[];
}
