import {
    CallPointAnswer,
    QuestionWithAnswer,
    QuestionWithAnswerAndComments,
    QuestionWithAnswerAndCommentsAndDate,
    SectionDetails
} from "../../../../../store/audit/helpers/auditHelpers";
import QuestionWithAnswerRow from "../../../../Form/QuestionWithAnswerRow";
import React from "react";
import QuestionWithAnswerAndCommentsRow from "../../../../Form/QuestionWithAnswerAndCommentsRow";
import QuestionWithAnswerAndCommentsAndDateRow from "../../../../Form/QuestionWithAnswerAndCommentsAndDateRow";
import FormRowWithText from "../../../../Form/FormRowWithText";
import SectionDetailsItem from "../../../../Form/SectionDetailsItem";
import CallpointRow from "../../../../Form/CallpointRow";

export function generateQuestionComponents<T>(args: QuestionComponentProps<T>) {
    const elements: JSX.Element[] = [];
    if (!args.checklist) return elements;

    const keys = args.questionOrder.length > 0 ? args.questionOrder : Object.keys(args.checklist);
    for (const key of keys) {
        const question:
            | QuestionWithAnswer
            | QuestionWithAnswerAndComments
            | QuestionWithAnswerAndCommentsAndDate
            | SectionDetails
            | CallPointAnswer
            | string =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            args.checklist[key];

        if (!question) continue;

        if (typeof question === "string") {
            elements.push(<FormRowWithText key={question} rowName={question} />);
            continue;
        }

        if ("callPoint" in question) {
            elements.push(
                <CallpointRow
                    key={question.name}
                    {...question}
                    onChange={(updatedQuestion: CallPointAnswer) =>
                        args.updateCallback(key, updatedQuestion)
                    }
                />
            );
            continue;
        }

        if ("date" in question) {
            elements.push(
                <QuestionWithAnswerAndCommentsAndDateRow
                    key={question.question}
                    {...question}
                    showYesNoAnswerOnly={args.showYesNoAnswerOnly}
                    questionKey={key}
                    formRowClassName={question.indentQuestion ? "pl-3" : ""}
                    onChange={(newQuestion: QuestionWithAnswerAndCommentsAndDate) =>
                        args.updateCallback(key, newQuestion)
                    }
                />
            );
            continue;
        }

        if ("details" in question) {
            elements.push(
                <SectionDetailsItem
                    key={question.sectionName}
                    {...question}
                    onChange={(newSection: SectionDetails) => args.updateCallback(key, newSection)}
                />
            );
            continue;
        }

        if ("comments" in question) {
            elements.push(
                <QuestionWithAnswerAndCommentsRow
                    key={question.question}
                    {...question}
                    showYesNoAnswerOnly={args.showYesNoAnswerOnly}
                    questionKey={key}
                    formRowClassName={question.indentQuestion ? "pl-3" : ""}
                    onChange={(newQuestion: QuestionWithAnswerAndComments) =>
                        args.updateCallback(key, newQuestion)
                    }
                />
            );
            continue;
        }

        if ("answer" in question) {
            elements.push(
                <QuestionWithAnswerRow
                    key={question.question}
                    {...question}
                    showYesNoAnswerOnly={args.showYesNoAnswerOnly}
                    questionKey={key}
                    formRowClassName={question.indentQuestion ? "pl-3" : ""}
                    onChange={(newQuestion: QuestionWithAnswer) =>
                        args.updateCallback(key, newQuestion)
                    }
                />
            );
        }
    }

    return elements;
}

interface QuestionComponentProps<T> {
    checklist: T;
    questionOrder: string[];
    updateCallback: (
        key: string,
        question:
            | QuestionWithAnswer
            | QuestionWithAnswerAndComments
            | QuestionWithAnswerAndCommentsAndDate
            | SectionDetails
            | CallPointAnswer
    ) => void;
    showYesNoAnswerOnly: boolean;
}
