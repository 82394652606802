import {
    Answer,
    CallPointAnswer,
    newCallPointAnswer,
    newQuestionWithAnswer,
    newQuestionWithAnswerAndComments,
    newQuestionWithAnswerAndCommentsAndDate,
    newSectionDetailItem,
    QuestionWithAnswer,
    QuestionWithAnswerAndComments,
    QuestionWithAnswerAndCommentsAndDate,
    SectionDetails
} from "../auditHelpers";

export interface FireSafetyAudit {
    weeklyChecks: FireSafetyWeeklyChecks;
    monthlyChecks: FireSafetyMonthlyChecks;
    biAnnualChecks: FireSafetyBiAnnualChecks;
    annualChecks: FireSafetyAnnualChecks;
    fiveYearlyChecks: FireSafety5YearChecks;
}

export interface FireSafetyWeeklyChecks {
    callPointQuestion: string;
    callPoint: CallPointAnswer;
    doorReleasesWorking: QuestionWithAnswer;
    fireExtinguishersChecked: QuestionWithAnswer;
    ceilingMountedDetectorsUnobstructed: QuestionWithAnswer;
    fireDoorsUnobstructedAndNotProppedOpen: QuestionWithAnswer;
    fireDoorsInWorkingOrder: QuestionWithAnswer;
    anyVisibleDamageToFireDoors: QuestionWithAnswer;
    fireExitsClearlyMarked: QuestionWithAnswer;
    fireRoutesUnobstructed: QuestionWithAnswer;
    fireRoutesClearlyMarked: QuestionWithAnswer;
    fireInstructionsPositionedInObviousPlace: QuestionWithAnswer;
    suitableAndSufficientFireSignageDisplayed: QuestionWithAnswer;
    kitchenFireBlanket: QuestionWithAnswer;
    exteriorMedicalGassesSignageDisplayed: QuestionWithAnswer;
    fireActionPlanDisplayedThroughoutBuilding: QuestionWithAnswer;
    fireAssemblyPointClearlySignposted: QuestionWithAnswer;
    weeklyDetails: SectionDetails;
}

export const fireSafetyWeeklyQuestionOrder: string[] = [
    "callPointQuestion",
    "callPoint",
    "doorReleasesWorking",
    "fireExtinguishersChecked",
    "ceilingMountedDetectorsUnobstructed",
    "fireDoorsUnobstructedAndNotProppedOpen",
    "fireDoorsInWorkingOrder",
    "anyVisibleDamageToFireDoors",
    "fireExitsClearlyMarked",
    "fireRoutesUnobstructed",
    "fireRoutesClearlyMarked",
    "fireInstructionsPositionedInObviousPlace",
    "suitableAndSufficientFireSignageDisplayed",
    "kitchenFireBlanket",
    "exteriorMedicalGassesSignageDisplayed",
    "fireActionPlanDisplayedThroughoutBuilding",
    "fireAssemblyPointClearlySignposted",
    "weeklyDetails"
];

export interface FireSafetyMonthlyChecks {
    emergencyLightingTested: QuestionWithAnswerAndComments;
    monthlyCheckDetails: SectionDetails;
}

export const fireSafetyMonthlyQuestionOrder: string[] = [
    "emergencyLightingTested",
    "monthlyCheckDetails"
];

export interface FireSafetyBiAnnualChecks {
    fireDrillActionedDetails: QuestionWithAnswerAndCommentsAndDate;
    fireAlarmPanelDetails: QuestionWithAnswerAndCommentsAndDate;
    smokeDetectorsDetails: QuestionWithAnswerAndCommentsAndDate;
    biannualCheckDetails: SectionDetails;
}
export const fireSafetyBiAnnualQuestionOrder: string[] = [
    "fireDrillActionedDetails",
    "fireAlarmPanelDetails",
    "smokeDetectorsDetails",
    "biannualCheckDetails"
];

export interface FireSafetyAnnualChecks {
    fireExtinguisherServiceDetails: QuestionWithAnswerAndCommentsAndDate;
    annualCheckDetails: SectionDetails;
    emergencyLightingTest: QuestionWithAnswerAndCommentsAndDate;
    patTest: QuestionWithAnswerAndCommentsAndDate;
}

export const fireSafetyAnnualQuestionOrder: string[] = [
    "fireExtinguisherServiceDetails",
    "emergencyLightingTest",
    "patTest",
    "annualCheckDetails"
];

export interface FireSafety5YearChecks {
    fireExtinguisherCalibrationDetails: QuestionWithAnswerAndCommentsAndDate;
    fiveYearElectricalInspectionDetails: QuestionWithAnswerAndCommentsAndDate;
    fiveYearCheckDetails: SectionDetails;
}

export const fireSafety5YearlyQuestionOrder: string[] = [
    "fireExtinguisherCalibrationDetails",
    "fiveYearElectricalInspectionDetails",
    "fiveYearCheckDetails"
];

export function generateBlankFireSafetyAudit(): FireSafetyAudit {
    return {
        weeklyChecks: generateWeeklyFireAuditQuestions(),
        monthlyChecks: generateMonthlyFireAuditQuestions(),
        biAnnualChecks: generateBiAnnualFireAuditQuestions(),
        annualChecks: generateAnnualFireAuditQuestions(),
        fiveYearlyChecks: generate5YearlyFireAuditQuestions()
    };
}

function generateWeeklyFireAuditQuestions(): FireSafetyWeeklyChecks {
    return {
        callPointQuestion: "Fire detection system tested?",
        callPoint: newCallPointAnswer("Call point?", 0),
        doorReleasesWorking: newQuestionWithAnswer("Door releases working?", Answer.No),
        fireExtinguishersChecked: newQuestionWithAnswer("Fire extinguishers checked?", Answer.No),
        ceilingMountedDetectorsUnobstructed: newQuestionWithAnswer(
            "Ceiling mounted detectors unobstructed?",
            Answer.No
        ),
        fireDoorsUnobstructedAndNotProppedOpen: newQuestionWithAnswer(
            "Fire doors unobstructed and not propped open?",
            Answer.No
        ),
        fireDoorsInWorkingOrder: newQuestionWithAnswer("Fire doors in working order?", Answer.No),
        anyVisibleDamageToFireDoors: newQuestionWithAnswer(
            "Any visible damage to fire doors?",
            Answer.No
        ),
        fireExitsClearlyMarked: newQuestionWithAnswer("Fire exits clearly marked?", Answer.No),
        fireRoutesUnobstructed: newQuestionWithAnswer("Fire routes unobstructed?", Answer.No),
        fireRoutesClearlyMarked: newQuestionWithAnswer("Fire routes clearly marked?", Answer.No),
        fireInstructionsPositionedInObviousPlace: newQuestionWithAnswer(
            "Fire instructions positioned in obvious places?",
            Answer.No
        ),
        suitableAndSufficientFireSignageDisplayed: newQuestionWithAnswer(
            "Suitable and sufficient fire signage displayed?",
            Answer.No
        ),
        kitchenFireBlanket: newQuestionWithAnswer("Fire blanket (kitchen)?", Answer.No),
        exteriorMedicalGassesSignageDisplayed: newQuestionWithAnswer(
            "Caution ‘medical gasses’ signage (building exterior)?",
            Answer.No
        ),
        fireActionPlanDisplayedThroughoutBuilding: newQuestionWithAnswer(
            "‘Fire Action Plan’ on display throughout the building?",
            Answer.No
        ),
        fireAssemblyPointClearlySignposted: newQuestionWithAnswer(
            "Fire assembly point clearly signposted?",
            Answer.No
        ),
        weeklyDetails: newSectionDetailItem("Weekly check comments/details")
    };
}

function generateMonthlyFireAuditQuestions(): FireSafetyMonthlyChecks {
    return {
        emergencyLightingTested: newQuestionWithAnswerAndComments(
            "Emergency lighting tested?",
            Answer.No
        ),
        monthlyCheckDetails: newSectionDetailItem("Monthly check comments/details")
    };
}

function generateBiAnnualFireAuditQuestions(): FireSafetyBiAnnualChecks {
    return {
        fireDrillActionedDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire drill actioned?",
            0,
            Answer.No,
            "Date"
        ),
        fireAlarmPanelDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire Alarm Panel?",
            0,
            Answer.No,
            "Service Date:"
        ),
        smokeDetectorsDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Smoke Detectors?",
            0,
            Answer.No,
            "Service Date:"
        ),
        biannualCheckDetails: newSectionDetailItem("Bi-Annual check comments/details")
    };
}

function generateAnnualFireAuditQuestions(): FireSafetyAnnualChecks {
    return {
        fireExtinguisherServiceDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire extinguisher service?",
            0,
            Answer.No,
            "Service Date:"
        ),
        annualCheckDetails: newSectionDetailItem("Annual check comments/details"),
        emergencyLightingTest: newQuestionWithAnswerAndCommentsAndDate(
            "Emergency Lighting Annual Test?",
            0,
            Answer.No,
            "Test Date:"
        ),
        patTest: newQuestionWithAnswerAndCommentsAndDate(
            "Annual PAT Testing?",
            0,
            Answer.No,
            "Test Date:"
        )
    };
}

function generate5YearlyFireAuditQuestions(): FireSafety5YearChecks {
    return {
        fireExtinguisherCalibrationDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire extinguisher 5 year calibration?",
            0,
            Answer.No,
            "Calibration date:"
        ),
        fiveYearElectricalInspectionDetails: newQuestionWithAnswerAndCommentsAndDate(
            "5 year electrical inspection?",
            0,
            Answer.No,
            "Inspection date:"
        ),
        fiveYearCheckDetails: newSectionDetailItem("5 year check comments/details")
    };
}
