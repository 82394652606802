import {
    Answer,
    newQuestionWithAnswerAndCommentsAndDate,
    newSectionDetailItem,
    QuestionWithAnswerAndCommentsAndDate,
    SectionDetails
} from "../auditHelpers";

export interface AnnualFireSafetyAudit {
    fireExtinguisherServiceDetails: QuestionWithAnswerAndCommentsAndDate;
    annualCheckDetails: SectionDetails;
    emergencyLightingTest: QuestionWithAnswerAndCommentsAndDate;
    patTest: QuestionWithAnswerAndCommentsAndDate;
}

export const fireSafetyAnnualQuestionOrder: string[] = [
    "fireExtinguisherServiceDetails",
    "emergencyLightingTest",
    "patTest",
    "annualCheckDetails"
];

export function generateBlankAnnualFireSafetyAudit(): AnnualFireSafetyAudit {
    return {
        fireExtinguisherServiceDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire extinguisher service?",
            0,
            Answer.No,
            "Service Date:"
        ),
        annualCheckDetails: newSectionDetailItem("Annual check comments/details"),
        emergencyLightingTest: newQuestionWithAnswerAndCommentsAndDate(
            "Emergency Lighting Annual Test?",
            0,
            Answer.No,
            "Test Date:"
        ),
        patTest: newQuestionWithAnswerAndCommentsAndDate(
            "Annual PAT Testing?",
            0,
            Answer.No,
            "Test Date:"
        )
    };
}
