import {
    Answer,
    newQuestionWithAnswer,
    newQuestionWithAnswerAndCommentsAndDate,
    QuestionWithAnswer,
    QuestionWithAnswerAndCommentsAndDate
} from "../auditHelpers";

export interface PremisesDepotAudit {
    officeQuestions: DepotOfficeQuestions;
    kitchenQuestions: DepotKitchenQuestions;
    electricalQuestions: DepotElectricalQuestions;
    securityQuestions: DepotSecurityQuestions;
    bathroomQuestions: DepotBathroomQuestions;
    clinicalWasteGeneralWasteQuestions: DepotClinicalWasteGeneralWasteQuestions;
    storageQuestions: DepotStorageQuestions;
    generalSignageQuestions: DepotGeneralSignageQuestions;
    externalAreaQuestions: DepotExternalAreaQuestions;
}

interface DepotOfficeQuestions {
    areasFreeFromAccumulatedMaterial: QuestionWithAnswer;
    heatersClearFromCombustibleMaterial: QuestionWithAnswer;
    accessAndExitsUnobstructed: QuestionWithAnswer;
    roomTemperatureAtAnAcceptableLevel: QuestionWithAnswer;
    floorCoveringsSecureAndFreeFromTrippingHazards: QuestionWithAnswer;
    communalFacilitiesInAdequateConditionOfRepair: QuestionWithAnswer;
    electricalExtensionLeadsCorrectlyInstalled: QuestionWithAnswer;
    adequateLightingLevelsInAllOfficeAreas: QuestionWithAnswer;
    firstAidKitAvailableAndReadilyAccessible: QuestionWithAnswer;
    communalPointsUnobstructedAndSecure: QuestionWithAnswer;
    kitchenAreaSurfacesClean: QuestionWithAnswer;
    drainFreeFromObviousBlockage: QuestionWithAnswer;
    handWashingFacilitiesAvailableIncludingSoapAndTowels: QuestionWithAnswer;
    handWashingInformationDisplayed: QuestionWithAnswer;
    confidentialWasteDisposedOfCorrectly: QuestionWithAnswer;
}

export const depotOfficeQuestionOrder: string[] = [
    "areasFreeFromAccumulatedMaterial",
    "heatersClearFromCombustibleMaterial",
    "accessAndExitsUnobstructed",
    "roomTemperatureAtAnAcceptableLevel",
    "floorCoveringsSecureAndFreeFromTrippingHazards",
    "communalFacilitiesInAdequateConditionOfRepair",
    "electricalExtensionLeadsCorrectlyInstalled",
    "adequateLightingLevelsInAllOfficeAreas",
    "firstAidKitAvailableAndReadilyAccessible",
    "communalPointsUnobstructedAndSecure",
    "kitchenAreaSurfacesClean",
    "drainFreeFromObviousBlockage",
    "handWashingFacilitiesAvailableIncludingSoapAndTowels",
    "handWashingInformationDisplayed",
    "confidentialWasteDisposedOfCorrectly"
];

interface DepotKitchenQuestions {
    surfacesCleanAndFreeOfFoodWaste: QuestionWithAnswer;
    foodStoredCorrectly: QuestionWithAnswer;
    fridgeTemperatureBetween3and5: QuestionWithAnswer;
    floorsMoppedUsingCorrectColourCodedMopAndBuckets: QuestionWithAnswer;
    drainsFreeFromObviousBlockages: QuestionWithAnswer;
    electricalExtensionLeadsCorrectlyInstalled: QuestionWithAnswer;
    communalPointsUnobstructedAndSecure: QuestionWithAnswer;
    handWashingFacilitiesAvailableIncludingSoapAndTowels: QuestionWithAnswer;
    handWashingInformationDisplayed: QuestionWithAnswer;
    areaFreeFromPestsAndVermin: QuestionWithAnswer;
    recyclingBinsAvailable: QuestionWithAnswer;
    sufficientRefuseBinsAvailableAndUndamaged: QuestionWithAnswer;
    buildUpOfRubbishAroundBins: QuestionWithAnswer;
    binsEmptiedRegularly: QuestionWithAnswer;
}

export const depotKitchenQuestionOrder: string[] = [
    "surfacesCleanAndFreeOfFoodWaste",
    "foodStoredCorrectly",
    "fridgeTemperatureBetween3and5",
    "floorsMoppedUsingCorrectColourCodedMopAndBuckets",
    "drainsFreeFromObviousBlockages",
    "electricalExtensionLeadsCorrectlyInstalled",
    "communalPointsUnobstructedAndSecure",
    "handWashingFacilitiesAvailableIncludingSoapAndTowels",
    "handWashingInformationDisplayed",
    "areaFreeFromPestsAndVermin",
    "recyclingBinsAvailable",
    "sufficientRefuseBinsAvailableAndUndamaged",
    "buildUpOfRubbishAroundBins",
    "binsEmptiedRegularly"
];

interface DepotElectricalQuestions {
    electricalAppliancesRegularlyPatTested: QuestionWithAnswer;
    allStickersVisibleOnAppliances: QuestionWithAnswer;
    dateOfLastPatCheck: QuestionWithAnswerAndCommentsAndDate;
}

export const depotElectricalQuestionOrder: string[] = [
    "electricalAppliancesRegularlyPatTested",
    "allStickersVisibleOnAppliances",
    "dateOfLastPatCheck"
];

interface DepotSecurityQuestions {
    doorsAndWindowsLocked: QuestionWithAnswer;
    alarmDetectionSystemTestedAndWorking: QuestionWithAnswer;
    vegetationDoesNotObscureVisibility: QuestionWithAnswer;
    perimeterFencingWallsGatesAreInGoodRepair: QuestionWithAnswer;
    externalWheelieBinsAreLocked: QuestionWithAnswer;
    cctvSystemsAreInWorkingOrder: QuestionWithAnswer;
    confidentialMaterialSecurelyKept: QuestionWithAnswer;
}

export const depotSecurityQuestionOrder: string[] = [
    "doorsAndWindowsLocked",
    "alarmDetectionSystemTestedAndWorking",
    "vegetationDoesNotObscureVisibility",
    "perimeterFencingWallsGatesAreInGoodRepair",
    "externalWheelieBinsAreLocked",
    "cctvSystemsAreInWorkingOrder",
    "confidentialMaterialSecurelyKept"
];

interface DepotBathroomQuestions {
    handWashingInformationOnDisplay: QuestionWithAnswer;
    surfacesClean: QuestionWithAnswer;
    floorsMoppedUsingCorrectColourCodedMopAndBuckets: QuestionWithAnswer;
    handWashingFacilitiesAvailableIncludingSoapAndTowels: QuestionWithAnswer;
    handWashingInformationDisplayed: QuestionWithAnswer;
    toiletAndSinksRegularlyCleaned: QuestionWithAnswer;
    drainsFreeFromObviousBlockages: QuestionWithAnswer;
}

export const depotBathroomQuestionOrder: string[] = [
    "handWashingInformationOnDisplay",
    "surfacesClean",
    "floorsMoppedUsingCorrectColourCodedMopAndBuckets",
    "handWashingFacilitiesAvailableIncludingSoapAndTowels",
    "handWashingInformationDisplayed",
    "toiletAndSinksRegularlyCleaned",
    "drainsFreeFromObviousBlockages"
];

interface DepotClinicalWasteGeneralWasteQuestions {
    clinicalWasteSignageOnDisplay: QuestionWithAnswer;
    recyclingBinsAvailable: QuestionWithAnswer;
    sufficientRefuseBinsAvailableAndUndamaged: QuestionWithAnswer;
    buildUpOfRubbishAroundBins: QuestionWithAnswer;
    binsEmptiedRegularly: QuestionWithAnswer;
    clinicalWasteCorrectlyStoredAndSegregated: QuestionWithAnswer;
    sharpsBoxesSealedAndStored: QuestionWithAnswer;
    confidentialWasteDisposedOfCorrectly: QuestionWithAnswer;
}

export const depotClinicalWasteGeneralWasteQuestionOrder: string[] = [
    "clinicalWasteSignageOnDisplay",
    "recyclingBinsAvailable",
    "sufficientRefuseBinsAvailableAndUndamaged",
    "buildUpOfRubbishAroundBins",
    "binsEmptiedRegularly",
    "clinicalWasteCorrectlyStoredAndSegregated",
    "sharpsBoxesSealedAndStored",
    "confidentialWasteDisposedOfCorrectly"
];

interface DepotStorageQuestions {
    gasStorageSignageOnDisplay: QuestionWithAnswer;
    coshhColourCodedCleaningSignageOnDisplay: QuestionWithAnswer;
    cleaningMaterialsAvailableInLineWithLegislation: QuestionWithAnswer;
    storageAreasKeptCleanAndTidy: QuestionWithAnswer;
    combustibleMaterialsStoredSafely: QuestionWithAnswer;
    flammableMaterialStoredCorrectly: QuestionWithAnswer;
    emptyContainersDisposedOf: QuestionWithAnswer;
    gasCylindersStoredCorrectlyAndSecure: QuestionWithAnswer;
    stackSystemNoExcessivelyLoaded: QuestionWithAnswer;
}

export const depotStorageQuestionOrder: string[] = [
    "gasStorageSignageOnDisplay",
    "coshhColourCodedCleaningSignageOnDisplay",
    "cleaningMaterialsAvailableInLineWithLegislation",
    "storageAreasKeptCleanAndTidy",
    "combustibleMaterialsStoredSafely",
    "flammableMaterialStoredCorrectly",
    "emptyContainersDisposedOf",
    "gasCylindersStoredCorrectlyAndSecure",
    "stackSystemNoExcessivelyLoaded"
];

interface DepotGeneralSignageQuestions {
    hseHealthAndSafetyLawPosterDisplayed: QuestionWithAnswer;
    certificateOfEmployersLiabilityInsuranceDisplayed: QuestionWithAnswer;
    cqcCertificateDisplayed: QuestionWithAnswer;
}

export const depotGeneralSignageQuestionOrder: string[] = [
    "hseHealthAndSafetyLawPosterDisplayed",
    "certificateOfEmployersLiabilityInsuranceDisplayed",
    "cqcCertificateDisplayed"
];

interface DepotExternalAreaQuestions {
    cautionMedicalGasesOnDisplay: QuestionWithAnswer;
    garageShuttersKeptClosedAndSecure: QuestionWithAnswer;
    pedestrianWalkWaysClearOfTripHazardsAndObstruction: QuestionWithAnswer;
    oilAbsorptionGranulesReadilyAvailable: QuestionWithAnswer;
    noSignsOfVerminOrPestsInTheArea: QuestionWithAnswer;
}

export const depotExternalAreaQuestionOrder: string[] = [
    "cautionMedicalGasesOnDisplay",
    "garageShuttersKeptClosedAndSecure",
    "pedestrianWalkWaysClearOfTripHazardsAndObstruction",
    "oilAbsorptionGranulesReadilyAvailable",
    "noSignsOfVerminOrPestsInTheArea"
];

export function generateBlankPremisesDepotAudit(): PremisesDepotAudit {
    return {
        officeQuestions: generateBlankOfficeQuestions(),
        kitchenQuestions: generateBlankKitchenQuestion(),
        electricalQuestions: generateBlankElectricalQuestions(),
        securityQuestions: generateBlankSecurityQuestions(),
        bathroomQuestions: generateBlankBathroomQuestions(),
        clinicalWasteGeneralWasteQuestions: generateBlankClinicalWasteGeneralWasteQuestions(),
        storageQuestions: generateBlankStorageQuestions(),
        generalSignageQuestions: generateBlankGeneralSignageQuestions(),
        externalAreaQuestions: generateBlankExternalAreaQuestions()
    };
}

function generateBlankOfficeQuestions(): DepotOfficeQuestions {
    return {
        areasFreeFromAccumulatedMaterial: newQuestionWithAnswer(
            "Areas free from accumulated material?",
            Answer.NotApplicable,
            2
        ),
        heatersClearFromCombustibleMaterial: newQuestionWithAnswer(
            "Heaters clear from combustible material?",
            Answer.NotApplicable,
            2
        ),
        accessAndExitsUnobstructed: newQuestionWithAnswer(
            "Access and exits unobstructed?",
            Answer.NotApplicable,
            2
        ),
        roomTemperatureAtAnAcceptableLevel: newQuestionWithAnswer(
            "Room temperature at an acceptable level (min 16°)?",
            Answer.NotApplicable,
            2
        ),
        floorCoveringsSecureAndFreeFromTrippingHazards: newQuestionWithAnswer(
            "Floor coverings secure and free from tripping hazards?",
            Answer.NotApplicable,
            2
        ),
        communalFacilitiesInAdequateConditionOfRepair: newQuestionWithAnswer(
            "Communal facilities in adequate condition of repair?",
            Answer.NotApplicable,
            2
        ),
        electricalExtensionLeadsCorrectlyInstalled: newQuestionWithAnswer(
            "Electrical extension leads correctly installed?",
            Answer.NotApplicable,
            5
        ),
        adequateLightingLevelsInAllOfficeAreas: newQuestionWithAnswer(
            "Adequate lighting levels in all office areas?",
            Answer.NotApplicable,
            2
        ),
        firstAidKitAvailableAndReadilyAccessible: newQuestionWithAnswer(
            "First aid kit available and readily accessible?",
            Answer.NotApplicable,
            2
        ),
        communalPointsUnobstructedAndSecure: newQuestionWithAnswer(
            "Communal points unobstructed and secure?",
            Answer.NotApplicable,
            5
        ),
        kitchenAreaSurfacesClean: newQuestionWithAnswer(
            "Kitchen area surfaces clean?",
            Answer.NotApplicable,
            2
        ),
        drainFreeFromObviousBlockage: newQuestionWithAnswer(
            "Drain free from obvious blockages ?",
            Answer.NotApplicable,
            2
        ),
        handWashingFacilitiesAvailableIncludingSoapAndTowels: newQuestionWithAnswer(
            "Hand washing facilities available including soap and towels?",
            Answer.NotApplicable,
            5
        ),
        handWashingInformationDisplayed: newQuestionWithAnswer(
            "Hand washing information displayed?",
            Answer.NotApplicable,
            5
        ),
        confidentialWasteDisposedOfCorrectly: newQuestionWithAnswer(
            "Confidential waste disposed of correctly?",
            Answer.NotApplicable,
            8
        )
    };
}

function generateBlankKitchenQuestion(): DepotKitchenQuestions {
    return {
        surfacesCleanAndFreeOfFoodWaste: newQuestionWithAnswer(
            "Surfaces clean and free of food/waste?",
            Answer.NotApplicable,
            2
        ),
        foodStoredCorrectly: newQuestionWithAnswer(
            "Food stored correctly?",
            Answer.NotApplicable,
            2
        ),
        fridgeTemperatureBetween3and5: newQuestionWithAnswer(
            "Fridge temperature between 3° and 5°?",
            Answer.NotApplicable,
            8
        ),
        floorsMoppedUsingCorrectColourCodedMopAndBuckets: newQuestionWithAnswer(
            "Floors mopped using correct coloured coded mop and buckets?",
            Answer.NotApplicable,
            2
        ),
        drainsFreeFromObviousBlockages: newQuestionWithAnswer(
            "Drains free from obvious blockages?",
            Answer.NotApplicable,
            2
        ),
        electricalExtensionLeadsCorrectlyInstalled: newQuestionWithAnswer(
            "Electrical extension leads correctly installed?",
            Answer.NotApplicable,
            5
        ),
        communalPointsUnobstructedAndSecure: newQuestionWithAnswer(
            "Communal points unobstructed and secure?",
            Answer.NotApplicable,
            5
        ),
        handWashingFacilitiesAvailableIncludingSoapAndTowels: newQuestionWithAnswer(
            "Hand washing facilities available including soap and towels?",
            Answer.NotApplicable,
            5
        ),
        handWashingInformationDisplayed: newQuestionWithAnswer(
            "Hand washing information displayed?",
            Answer.NotApplicable,
            5
        ),
        areaFreeFromPestsAndVermin: newQuestionWithAnswer(
            "Area free from vermin/pests?",
            Answer.NotApplicable,
            5
        ),
        recyclingBinsAvailable: newQuestionWithAnswer(
            "Recycling bins available?",
            Answer.NotApplicable,
            2
        ),
        sufficientRefuseBinsAvailableAndUndamaged: newQuestionWithAnswer(
            "Sufficient refuse bins available and undamaged?",
            Answer.NotApplicable,
            2
        ),
        buildUpOfRubbishAroundBins: newQuestionWithAnswer(
            "Build up of rubbish around bins?",
            Answer.NotApplicable,
            2
        ),
        binsEmptiedRegularly: newQuestionWithAnswer(
            "Bins emptied regularly?",
            Answer.NotApplicable,
            2
        )
    };
}

function generateBlankElectricalQuestions(): DepotElectricalQuestions {
    return {
        electricalAppliancesRegularlyPatTested: newQuestionWithAnswer(
            "Electrical appliances regularly PAT tested?",
            Answer.NotApplicable,
            5
        ),
        allStickersVisibleOnAppliances: newQuestionWithAnswer(
            "All test stickers/labels visible on appliances?",
            Answer.NotApplicable,
            5
        ),
        dateOfLastPatCheck: newQuestionWithAnswerAndCommentsAndDate(
            "Date of last PAT test check?",
            0,
            Answer.NotApplicable,
            "Date"
        )
    };
}

function generateBlankSecurityQuestions(): DepotSecurityQuestions {
    return {
        doorsAndWindowsLocked: newQuestionWithAnswer(
            "Doors and windows locked (OOH)?",
            Answer.NotApplicable,
            5
        ),
        alarmDetectionSystemTestedAndWorking: newQuestionWithAnswer(
            "Alarm detection systems tested and working?",
            Answer.NotApplicable,
            5
        ),
        vegetationDoesNotObscureVisibility: newQuestionWithAnswer(
            "Vegetation does not obscure visibility and is well maintained?",
            Answer.NotApplicable,
            2
        ),
        perimeterFencingWallsGatesAreInGoodRepair: newQuestionWithAnswer(
            "Perimeter fencing/walls/gates are in good repair?",
            Answer.NotApplicable,
            2
        ),
        externalWheelieBinsAreLocked: newQuestionWithAnswer(
            "External wheelie-bins are locked?",
            Answer.NotApplicable,
            2
        ),
        cctvSystemsAreInWorkingOrder: newQuestionWithAnswer(
            "CCTV systems in working order?",
            Answer.NotApplicable,
            5
        ),
        confidentialMaterialSecurelyKept: newQuestionWithAnswer(
            "Confidential material securely kept?",
            Answer.NotApplicable,
            8
        )
    };
}

function generateBlankBathroomQuestions(): DepotBathroomQuestions {
    return {
        handWashingInformationOnDisplay: newQuestionWithAnswer(
            "Hand Washing information on display?",
            Answer.NotApplicable,
            2
        ),
        surfacesClean: newQuestionWithAnswer("Surfaces clean?", Answer.NotApplicable, 2),
        floorsMoppedUsingCorrectColourCodedMopAndBuckets: newQuestionWithAnswer(
            "Confidential material securely kept?",
            Answer.NotApplicable,
            2
        ),
        handWashingFacilitiesAvailableIncludingSoapAndTowels: newQuestionWithAnswer(
            "Floors mopped using correct coloured coded mop and buckets?",
            Answer.NotApplicable,
            5
        ),
        handWashingInformationDisplayed: newQuestionWithAnswer(
            "Hand washing facilities available including soap and towels?",
            Answer.NotApplicable,
            5
        ),
        toiletAndSinksRegularlyCleaned: newQuestionWithAnswer(
            "Toilet and sinks regularly cleaned?",
            Answer.NotApplicable,
            2
        ),
        drainsFreeFromObviousBlockages: newQuestionWithAnswer(
            "Drains and toilets free from obvious blockage?",
            Answer.NotApplicable,
            2
        )
    };
}

function generateBlankClinicalWasteGeneralWasteQuestions(): DepotClinicalWasteGeneralWasteQuestions {
    return {
        clinicalWasteSignageOnDisplay: newQuestionWithAnswer(
            "Clinical Waste signage on display?",
            Answer.NotApplicable,
            2
        ),
        recyclingBinsAvailable: newQuestionWithAnswer(
            "Recycling bins available?",
            Answer.NotApplicable,
            2
        ),
        sufficientRefuseBinsAvailableAndUndamaged: newQuestionWithAnswer(
            "Sufficient refuse bins available and undamaged?",
            Answer.NotApplicable,
            2
        ),
        buildUpOfRubbishAroundBins: newQuestionWithAnswer(
            "Build up of rubbish around bins?",
            Answer.NotApplicable,
            2
        ),
        binsEmptiedRegularly: newQuestionWithAnswer(
            "Bins emptied regularly?",
            Answer.NotApplicable,
            2
        ),
        clinicalWasteCorrectlyStoredAndSegregated: newQuestionWithAnswer(
            "Clinical waste correctly stored and segregated?",
            Answer.NotApplicable,
            5
        ),
        sharpsBoxesSealedAndStored: newQuestionWithAnswer(
            "Sharps boxes sealed and stored?",
            Answer.NotApplicable,
            5
        ),
        confidentialWasteDisposedOfCorrectly: newQuestionWithAnswer(
            "Confidential waste disposed of correctly?",
            Answer.NotApplicable,
            8
        )
    };
}

function generateBlankStorageQuestions(): DepotStorageQuestions {
    return {
        gasStorageSignageOnDisplay: newQuestionWithAnswer(
            "Gas Storage signage on display?",
            Answer.NotApplicable,
            2
        ),
        coshhColourCodedCleaningSignageOnDisplay: newQuestionWithAnswer(
            "COSHH colour coded cleaning signage on display?",
            Answer.NotApplicable,
            2
        ),
        cleaningMaterialsAvailableInLineWithLegislation: newQuestionWithAnswer(
            "Cleaning materials available in line with legislation?",
            Answer.NotApplicable,
            2
        ),
        storageAreasKeptCleanAndTidy: newQuestionWithAnswer(
            "Storage areas kept clean and tidy?",
            Answer.NotApplicable,
            2
        ),
        combustibleMaterialsStoredSafely: newQuestionWithAnswer(
            "Combustible materials stored safely?",
            Answer.NotApplicable,
            5
        ),
        flammableMaterialStoredCorrectly: newQuestionWithAnswer(
            "Flammable material stored correctly?",
            Answer.NotApplicable,
            5
        ),
        emptyContainersDisposedOf: newQuestionWithAnswer(
            "Empty containers disposed of?",
            Answer.NotApplicable,
            2
        ),
        gasCylindersStoredCorrectlyAndSecure: newQuestionWithAnswer(
            "Gas cylinders stored correctly and secure?",
            Answer.NotApplicable,
            5
        ),
        stackSystemNoExcessivelyLoaded: newQuestionWithAnswer(
            "Stacking system not excessively loaded?",
            Answer.NotApplicable,
            5
        )
    };
}

function generateBlankGeneralSignageQuestions(): DepotGeneralSignageQuestions {
    return {
        hseHealthAndSafetyLawPosterDisplayed: newQuestionWithAnswer(
            "HSE Health and Safety Law Poster completed and on display?",
            Answer.NotApplicable,
            2
        ),
        certificateOfEmployersLiabilityInsuranceDisplayed: newQuestionWithAnswer(
            "Certificate of Employers Liability Insurance in date and on display?",
            Answer.NotApplicable,
            2
        ),
        cqcCertificateDisplayed: newQuestionWithAnswer(
            "CQC Certificate of Registration document on display?",
            Answer.NotApplicable,
            2
        )
    };
}

function generateBlankExternalAreaQuestions(): DepotExternalAreaQuestions {
    return {
        cautionMedicalGasesOnDisplay: newQuestionWithAnswer(
            "Caution Medical Gases signage on display?",
            Answer.NotApplicable,
            2
        ),
        garageShuttersKeptClosedAndSecure: newQuestionWithAnswer(
            "Garage shutters kept closed and secure?",
            Answer.NotApplicable,
            5
        ),
        pedestrianWalkWaysClearOfTripHazardsAndObstruction: newQuestionWithAnswer(
            "Pedestrian walk ways clear of trip hazards and obstruction?",
            Answer.NotApplicable,
            2
        ),
        oilAbsorptionGranulesReadilyAvailable: newQuestionWithAnswer(
            "Oil absorption granules readily available?",
            Answer.NotApplicable,
            2
        ),
        noSignsOfVerminOrPestsInTheArea: newQuestionWithAnswer(
            "No signs of vermin/pests in the area?",
            Answer.NotApplicable,
            2
        )
    };
}
