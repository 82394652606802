import React from "react";
import {Answer, QuestionWithAnswerAndComments, YesNo} from "../../store/audit/helpers/auditHelpers";
import GenericTypeDropdown from "../Dropdown/GenericTypeDropdown";
import {OptionsFromType} from "../Dropdown/Helpers/dropdownUtils";
import {useIsHistoricAudit} from "../Hooks/useIsHistoricAudit";
import {getUiFriendlyText} from "../../utils/textUtils";
import FormRow from "./FormRow";
import {DebounceInput} from "react-debounce-input";

const QuestionWithAnswerAndCommentsRow = (props: QuestionWithAnswerAndCommentsWithCallback) => {
    const isHistoric = useIsHistoricAudit();

    /** Gets the value of the Answer enum from a string  */
    const getAnswerFromString = (value: string): Answer => {
        return Answer[value as keyof typeof Answer];
    };

    return (
        <div className="complex-question">
            <FormRow
                rowName={props.question}
                columnDetailClassName={!isHistoric ? "pl-0 pr-0" : ""}
            >
                {!isHistoric ? (
                    <GenericTypeDropdown
                        enumOptions={props.showYesNoAnswerOnly ? YesNo : Answer}
                        splitByCapitalLetter={true}
                        getOptionsFrom={OptionsFromType.Key}
                        searchWithDecapitalisedFirstLetter={false}
                        clearable={false}
                        searchable={false}
                        changeOption={(item) => {
                            const newAnswer = getAnswerFromString(item.toString());
                            props.onChange({
                                ...props,
                                answer: newAnswer
                            });
                        }}
                        id={props.answer}
                        disabled={false}
                    />
                ) : (
                    <p className="body-font">{getUiFriendlyText(props.answer)}</p>
                )}
            </FormRow>
            <FormRow rowName={"Comments"} columnDetailClassName={!isHistoric ? "pl-0 pr-0" : ""}>
                {!isHistoric ? (
                    <DebounceInput
                        debounceTimeout={300}
                        element={"textarea"}
                        className="input-fields text-area-inputs"
                        value={props.comments}
                        onChange={(event) => {
                            const {value} = event.target;

                            props.onChange({
                                ...props,
                                comments: value
                            });
                        }}
                        placeholder="Enter details here..."
                    />
                ) : (
                    <p className="body-font">{props.comments}</p>
                )}
            </FormRow>
        </div>
    );
};

export default QuestionWithAnswerAndCommentsRow;

/** Interface containing new answer in a callback*/
interface QuestionWithAnswerAndCommentsWithCallback extends QuestionWithAnswerAndComments {
    onChange: (updatedQuestion: QuestionWithAnswerAndComments) => void;
    question: string;
    rowClassName?: string;
    formRowClassName?: string;
    questionKey: string;
    showYesNoAnswerOnly: boolean;
}
