import React from "react";
import {Answer, AuditForm} from "../../../../../../store/audit/helpers/auditHelpers";
import RiskAssessmentAuditForm from "../RiskAssessment/RiskAssessmentAuditForm";
import PremisesDepotAuditForm from "../PremisesAudits/PremisesDepot/PremisesDepotAuditForm";
import {generateBlankPremisesDepotAudit} from "../../../../../../store/audit/helpers/Premises/premisesDepotAudit";
import {generateBlankHeadOfficeAudit} from "../../../../../../store/audit/helpers/Premises/premisesHeadOfficeAudit";
import PremisesHeadOfficeAuditForm from "../PremisesAudits/PremisesHeadOffice/PremisesHeadOfficeAuditForm";
import {generateBlankPremisesTrainingAndStorageAudit} from "../../../../../../store/audit/helpers/Premises/premisesTrainingAndStorageAudit";
import PremisesTrainingAndSafetyAuditForm from "../PremisesAudits/PremisesTrainingAndSafety/PremisesTrainingAndSafetyAuditForm";
import {generateBlankFireSafetyAudit} from "../../../../../../store/audit/helpers/FireSafety/fireSafetyAuditHelpers";
import FireSafetyAuditForm from "../FireSafetyAudits/FireSafety/FireSafetyAuditForm";
import {generateBlankWeeklyFireSafetyAudit} from "../../../../../../store/audit/helpers/FireSafety/weeklyFireSafetyAudit";
import WeeklyFireAudit from "../FireSafetyAudits/Weekly/WeeklyFireAudit";
import {generateBlankMonthlyFireSafetyAudit} from "../../../../../../store/audit/helpers/FireSafety/monthlyFireSafetyAudit";
import MonthlyFireAudit from "../FireSafetyAudits/Monthly/MonthlyFireAudit";
import {generateBlankBiAnnualFireSafetyAudit} from "../../../../../../store/audit/helpers/FireSafety/biAnnualFireSafetyAudit";
import BiAnnualFireAudit from "../FireSafetyAudits/BiAnnual/BiAnnualFireAudit";
import {generateBlankAnnualFireSafetyAudit} from "../../../../../../store/audit/helpers/FireSafety/annualFireSafetyAudit";
import AnnualFireAudit from "../FireSafetyAudits/Annual/AnnualFireAudit";
import {generateBlankFiveYearFireSafetyAudit} from "../../../../../../store/audit/helpers/FireSafety/fiveYearFireSafetyAudit";
import FiveYearFireAudit from "../FireSafetyAudits/FiveYear/FiveYearFireAudit";

export default function AuditFormContainer(props: AuditForm) {
    if (props.riskAssessmentMatrixAudit)
        return <RiskAssessmentAuditForm {...props.riskAssessmentMatrixAudit} />;

    if (props.premisesDepotAudit) {
        const existing = props.premisesDepotAudit;
        const blank = generateBlankPremisesDepotAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <PremisesDepotAuditForm {...combined} />;
    }

    if (props.premisesHeadOfficeAudit) {
        const existing = props.premisesHeadOfficeAudit;
        const blank = generateBlankHeadOfficeAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <PremisesHeadOfficeAuditForm {...combined} />;
    }

    if (props.premisesTrainingStorageAreaAudit) {
        const existing = props.premisesTrainingStorageAreaAudit;
        const blank = generateBlankPremisesTrainingAndStorageAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <PremisesTrainingAndSafetyAuditForm {...combined} />;
    }

    if (props.fireSafetyAudit) {
        const existing = props.fireSafetyAudit;
        const blank = generateBlankFireSafetyAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <FireSafetyAuditForm {...combined} />;
    }

    if (props.weeklyFireSafetyAudit) {
        const existing = props.weeklyFireSafetyAudit;
        const blank = generateBlankWeeklyFireSafetyAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <WeeklyFireAudit {...combined} />;
    }

    if (props.monthlyFireSafetyAudit) {
        const existing = props.monthlyFireSafetyAudit;
        const blank = generateBlankMonthlyFireSafetyAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <MonthlyFireAudit {...combined} />;
    }

    if (props.biAnnualFireSafetyAudit) {
        const existing = props.biAnnualFireSafetyAudit;
        const blank = generateBlankBiAnnualFireSafetyAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <BiAnnualFireAudit {...combined} />;
    }

    if (props.annualFireSafetyAudit) {
        const existing = props.annualFireSafetyAudit;
        const blank = generateBlankAnnualFireSafetyAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <AnnualFireAudit {...combined} />;
    }

    if (props.fiveYearFireSafetyAudit) {
        const existing = props.fiveYearFireSafetyAudit;
        const blank = generateBlankFiveYearFireSafetyAudit();
        const combined = autoAddMissingQuestions(props.isHistoric, existing, blank);
        return <FiveYearFireAudit {...combined} />;
    }

    return null;
}

function setAllAnswersToNotPreviouslyAnswered<T extends object>(obj: T) {
    // Direct answer
    if (Object.hasOwn(obj, "answer")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        obj.answer = Answer.NotPreviouslyAnswered;
        return;
    }

    // Check each property, going into it to for nested answers
    for (const key of Object.keys(obj)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (typeof obj[key] === "object") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setAllAnswersToNotPreviouslyAnswered(obj[key]);
        }
    }

    return obj;
}

function mergeProperties<T extends object>(existing: T, blank: T) {
    for (const key of Object.keys(blank)) {
        // Sometimes we end up in a property that doesn't really exist (null/undefined)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!blank[key]) continue;

        try {
            // Completely missing, wholesale add the property
            if (!Object.hasOwn(existing, key)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                existing[key] = blank[key];
                continue;
            }

            // Check nested properties for potential missing properties
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (typeof existing[key] === "object" && typeof blank[key] === "object") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mergeProperties(existing[key], blank[key]);
            }
        } catch (e) {
            console.log("error with key", key, e);
        }
    }
}

function autoAddMissingQuestions<T extends object>(
    isHistoric: boolean | undefined,
    existing: T,
    blank: T
): T {
    if (isHistoric) return existing;

    setAllAnswersToNotPreviouslyAnswered(blank);
    mergeProperties(existing, blank);

    return existing;
}
