import React from "react";
import {RiskManagementStats} from "../../../../../api/rm";
import FormHeader from "../../../../Form/FormHeader";
import HalfDonut from "../../../../Stats/HalfDonut";
import {getAngleForHalfPieChartFromPercentage} from "../../../../../utils/mathUtils";

const ComplianceStats = ({
    premisesCompliance,
    fireSafetyCompliance,
    totalAmountFireSafety,
    totalAmountPremises,
    totalNewAudits
}: RiskManagementStats) => {
    const getNumberOfRiskAssessmentsMade = () => {
        return totalNewAudits - totalAmountPremises - totalAmountFireSafety;
    };
    return (
        <React.Fragment>
            <div className="pb-4">
                <FormHeader headerName={"Compliance Stats"} />
                <div className="stat-item-wrapper">
                    <HalfDonut
                        tooltipText={`Overall Premises Compliance: ${premisesCompliance}%`}
                        totalValue={totalNewAudits}
                        needleAngle={getAngleForHalfPieChartFromPercentage(premisesCompliance)}
                        outerLabel={"Premises Compliance"}
                        compliancePercentage={premisesCompliance}
                    />
                    <HalfDonut
                        tooltipText={`Overall Fire Safety Compliance: ${fireSafetyCompliance}%`}
                        totalValue={totalNewAudits}
                        needleAngle={getAngleForHalfPieChartFromPercentage(fireSafetyCompliance)}
                        outerLabel={"Fire Safety Compliance"}
                        compliancePercentage={fireSafetyCompliance}
                    />
                    <HalfDonut
                        tooltipText={`Number of Fire Safety audits completed this month (${totalAmountFireSafety}/${totalNewAudits})`}
                        totalValue={totalNewAudits}
                        needleAngle={getAngleForHalfPieChartFromPercentage(
                            getPercentage(totalAmountFireSafety, totalNewAudits)
                        )}
                        outerLabel={"Number of Fire Safety Audits"}
                        compliancePercentage={getPercentage(totalAmountFireSafety, totalNewAudits)}
                    />
                    <HalfDonut
                        tooltipText={`Number of Premises audits completed this month (${totalAmountPremises}/${totalNewAudits})`}
                        totalValue={totalNewAudits}
                        needleAngle={getAngleForHalfPieChartFromPercentage(
                            getPercentage(totalAmountPremises, totalNewAudits)
                        )}
                        outerLabel={"Number of Premises Audits"}
                        compliancePercentage={getPercentage(totalAmountPremises, totalNewAudits)}
                    />
                    <HalfDonut
                        tooltipText={`Number of Risk Assessments completed this month (${getNumberOfRiskAssessmentsMade()}/${totalNewAudits})`}
                        totalValue={getNumberOfRiskAssessmentsMade()}
                        needleAngle={getAngleForHalfPieChartFromPercentage(
                            getPercentage(getNumberOfRiskAssessmentsMade(), totalNewAudits)
                        )}
                        outerLabel={"Number of Risk Assessments"}
                        compliancePercentage={getPercentage(
                            getNumberOfRiskAssessmentsMade(),
                            totalNewAudits
                        )}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ComplianceStats;

function getPercentage(val1: number, val2: number): number {
    return (val1 / val2) * 100;
}
