import React from "react";
import {CallPointAnswer} from "../../store/audit/helpers/auditHelpers";
import {useIsHistoricAudit} from "../Hooks/useIsHistoricAudit";
import FormRow from "./FormRow";
import Select from "react-select";

/** Interface containing new answer in a callback*/
interface CallpointCallback extends CallPointAnswer {
    onChange: (updatedQuestion: CallPointAnswer) => void;
}

function formatPointNumber(point: number): string {
    if (point <= 0) return "None";
    return point.toString();
}

function CallpointDisplay(props: CallpointCallback) {
    const allPoints = new Set<number>();
    if (props.prevCallPoints && props.prevCallPoints.length > 0)
        props.prevCallPoints.forEach((point) => allPoints.add(point));
    allPoints.add(props.callPoint);

    const points = Array.from(allPoints)
        .sort((a, b) => a - b)
        .map((point) => formatPointNumber(point))
        .join(", ");

    return <p>{points}</p>;
}

export default function CallpointRow(props: CallpointCallback) {
    const isHistoric = useIsHistoricAudit();

    // Historic is a special-case read-only view
    if (isHistoric) {
        return (
            <FormRow rowName={props.name}>
                <CallpointDisplay {...props} />
            </FormRow>
        );
    }

    const maxPointCount = 12 + 1;
    const options = Array.from({length: maxPointCount}, (_, i) => {
        return {
            value: i,
            label: i.toString()
        };
    });
    options[0].label = "None";
    const selected = options.find((o) => o.value === props.callPoint);

    return (
        <React.Fragment>
            <FormRow rowName={props.name} columnDetailClassName="pl-0 pr-0">
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={options}
                    onChange={(v) => {
                        const value = v?.value ?? 0;
                        props.onChange({
                            ...props,
                            callPoint: value
                        });
                    }}
                    isSearchable={false}
                    value={selected}
                    noOptionsMessage={() => "No Options Found"}
                    isDisabled={false}
                    isClearable={false}
                    menuPortalTarget={document.body}
                />
            </FormRow>
        </React.Fragment>
    );
}
